import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const typeGallery = (() => {

  const pageType = (document.querySelector('#pageType.Atype') != null || document.querySelector('#pageType.Dtype') != null);
  if (pageType) {
    var TthumbsSwiper = new Swiper('.TthumbsSwiper', {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 10,
    });
    var TgallerySwiper = new Swiper('.TgallerySwiper', {
      loop: true,
      spaceBetween: 0,
      navigation: {
        nextEl: '.Mnext',
        prevEl: '.Mprev',
      },
      thumbs: {
        swiper: TthumbsSwiper,
      },
    });
  }

})();
export default typeGallery;

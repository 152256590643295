const comZoom = (() => {

  const zoom = document.querySelectorAll('.js-com-zoom');
  if (zoom.length > 0) {

    for (let i = 0; i < zoom.length; i++) {
      gsap.set(zoom[i], { opacity: 1,});

      gsap.to(zoom[i], {
        opacity: 0,
        repeat: 2,
        duration: 1.8,
        scrollTrigger: {
          trigger: zoom[i],
          start: 'top 80%'
        }
      });
    }

  }

})();
export default comZoom;
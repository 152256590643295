import { scrollControlFunc, windowResize } from './index';

const loadFunc = (() => {

  const loadWrap = (document.getElementById("jsLoadWrap") != null);
  if (loadWrap) {
    // scrollControlFunc.noScroll();
    windowResize();

    const startFunc = () => {
      loadWrap.classList.add('no-act');
      // topFirst();
      // comMain();
      const pageTop = (document.getElementById("pageTop") != null);
      if (!pageTop) {
        scrollControlFunc.returnScroll();
      }
    }

    // document.addEventListener("DOMContentLoaded", () => {
    //   setTimeout(startFunc, 100)
    // });
    window.onload = () => {
      startFunc();
      //setTimeout(startFunc, 100)
    };
  }

})();
export default loadFunc;
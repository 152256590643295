import { scrollControlFunc,} from './index';

const topLink = (() => {

  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    const topLinkImg = document.querySelectorAll('.js-topLink');
    const topLinkTxt = document.querySelectorAll('.page-ttl');
    console.log(topLinkTxt);
    gsap.set('.page-ttl', {
      opacity: 0,
      y: 100,
    });

    topLinkImg.forEach((topLinkImg) => {
      ScrollTrigger.create({
        trigger: topLinkImg,
        start: 'top 30%',
        // markers: true,
        onEnter: () => {
          topLinkImg.classList.add('act'); // actクラスを追加
          scrollControlFunc.noScroll();
          setTimeout(() => {
            scrollControlFunc.returnScroll();
          }, 800);
        },
        onLeaveBack: () => {
          topLinkImg.classList.remove('act'); // actクラスを削除
        },
      });
    });

    topLinkTxt.forEach((topLinkTxt) => {
      ScrollTrigger.create({
        trigger: topLinkTxt,
        start: 'top 60%',
        // markers: true,
        onEnter: () => {
          gsap.to(topLinkTxt, {
            opacity: 1,
            y: 0,
            duration: 0.8,
          });
        },
      });
    });

    



  }

})();
export default topLink;
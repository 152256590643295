const comTitle = (() => {

  var title = document.querySelectorAll('.com-cnt-ttl');
  if (title.length > 0) {
    for (let i = 0; i < title.length; i++) {
      gsap.set(title[i],{
        clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
      });
      

      gsap.to(title[i], {
        duration: 0.8,
        ease: "sine.inOut",
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        scrollTrigger: {
          trigger: title[i],
          start: 'top 84%',
          // markers: true,
        }
      });


    }

  }

})();
export default comTitle;
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const gallery = (() => {

  const pageGallery = (document.getElementById('pageGallery') != null);
  if (pageGallery) {

    var MthumbsSwiper = new Swiper('.MthumbsSwiper', {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 10,
    });
    var MgallerySwiper = new Swiper('.MgallerySwiper', {
      loop: true,
      spaceBetween: 0,
      navigation: {
        nextEl: '.Mnext',
        prevEl: '.Mprev',
      },
      thumbs: {
        swiper: MthumbsSwiper,
      },
    });

    var MthumbsSwiper = new Swiper('.CthumbsSwiper', {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 10,
    });
    var MgallerySwiper = new Swiper('.CgallerySwiper', {
      loop: true,
      spaceBetween: 0,
      navigation: {
        nextEl: '.Cnext',
        prevEl: '.Cprev',
      },
      thumbs: {
        swiper: MthumbsSwiper,
      },
    });

  }


})();
export default gallery;

import { scrollControlFunc,} from './index';

const modal = (() => {

  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    const body = document.querySelector('body');
    const btnOpen = document.querySelector('.js-btnModal');
    const btnClose = document.querySelector('.js-btnClose');
    const modalWrap = document.querySelector('.modal-wrap');
    let youtube = document.querySelector('iframe').contentWindow;
    const controlVideo = (func) => {
      let message = '{"event": "command", "func": "' + func + '", "args": ""}';
      youtube.postMessage(message, '*');
    };

    btnOpen.addEventListener('click', () => {
      modalWrap.classList.add("show");
      scrollControlFunc.noScroll();
      controlVideo('playVideo');
    });

    btnClose.addEventListener('click', () =>{
      modalWrap.classList.remove("show");
      scrollControlFunc.returnScroll();
      controlVideo('pauseVideo');
    });
    modalWrap.addEventListener('click', () =>{
      const checkBody = body.classList.contains('pc');
        if (!checkBody) {//クラスPCなしのとき
        } else {
          modalWrap.classList.remove("show");
          scrollControlFunc.returnScroll();
          controlVideo('pauseVideo');
        }
    });

  }

})();
export default modal;
const comFade = (() => {

  const fadeElem = document.querySelectorAll('.js-com-fade');
  if (fadeElem.length > 0) {

    for (let i = 0; i < fadeElem.length; i++) {
      gsap.set(fadeElem[i], { opacity: 0, y: 20, });

      gsap.to(fadeElem[i], {
        opacity: 1,
        y: 0,
        duration: 1.5,
        ease: "power1.out",
        scrollTrigger: {
          trigger: fadeElem[i],
          start: 'top 80%',
          // markers: true,
        }
      });
    }

  }

})();
export default comFade;
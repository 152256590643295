const scrollNav = (() => {

  let gnav = document.getElementById('jsGnav');
  let header = document.getElementById('jsheader');
  // console.log(header);

  gsap.to(gnav, {});
  
  gsap.to(gnav,{
    scrollTrigger: {
      trigger: 'body',
      start: 'top top',
      toggleClass: { targets: header, className: "none" },
      // markers: true
    }
  });
  

})();
export default scrollNav;
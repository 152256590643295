const comScroll = (() => {

  const scrool = document.querySelectorAll('.js-com-hscroll');
  const scrollWrap = document.querySelectorAll('.com-hscroll-wrap');

  if (scrool.length > 0) {
    for (let i = 0; i < scrool.length; i++) {
      gsap.set(scrool[i], {
        xPercent: 100,
      });

      gsap.to(scrool[i], {
        xPercent: 0,
        duration: 1.2,
        scrollTrigger: {
          trigger: scrool[i],
          start: 'top 80%'
        },
      });

      scrollWrap[i].addEventListener('scroll', () => {
        gsap.to(scrool[i], {
          x: 80,
          opacity: 0,
          duration: 0.8,
          ease: "since.easeInOut",
        });
        
      });

    }

  }

})();
export default comScroll;
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const topFV = (() => {

  const pageGallery = (document.getElementById('pageTop') != null);
  if (pageGallery) {

    var topSwiper = new Swiper('.topSwiper', {
      effect: 'fade',
      speed: 3000,
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 6000,
      },
      loop: true,
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      }
    });

  }


})();
export default topFV;

// const pageTop = (document.getElementById("pageTop") != null);
export { default as userAgent } from './userAgent';
export { default as windowResize } from './windowResize';
export { default as scrollControlFunc } from './scrollControlFunc';
export { default as scrollAnchorFunc } from './scrollAnchorFunc';
export { default as loadImg } from './loadImg';
export { default as navFunc } from './navFunc';
export { default as scrollNav } from './scrollNav';

export { default as comMainTxt } from './comMainTxt';
export { default as comFade } from './comFade';
export { default as comImgFade } from './comImgFade';
export { default as comZoom } from './comZoom';
export { default as comScroll } from './comScroll';
export { default as modal } from './modal';

export { default as comBg } from './comBg';
// export { default as topMain } from './topMain';
export { default as topFv } from './topFv';
export { default as topLink } from './topLink';
export { default as comTitle } from './comTitle';
// export { default as access } from './access';
export { default as lifeinfo } from './lifeinfo';
export { default as gallery } from './gallery';
export { default as typeGallery } from './typeGallery';
export { default as typeSlider } from './typeSlider';

// export { default as loadImgSample } from './loadImgSample';
export { default as loadFunc } from './loadFunc';
const comMainTxt = (() => {

    gsap.set(".js-com-maintxt", {
      opacity: 0,
      y: 50,
    });

    gsap.to(".js-com-maintxt", {
      opacity: 1,
      y: 0,
      duration: 2,
      ease: "power1.inOut",
    });

  
})();

export default comMainTxt;
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const typeSlider = (() => {

  const pageName = document.querySelector('#pageType'),
        pageType = (pageName != null),
        typeName = pageName.classList[0];
  let menu;

  if (pageType) {

  
    if (typeName == 'Atype' || typeName == 'Btype' || typeName == 'Dtype') menu = ['家具レイアウト', '通常間取り'];
    else if (typeName == 'Ctype') menu = ['家具レイアウト<span>（ディンクス向け）</span>', '家具レイアウト<span>（単身者向け）</span>', '通常間取り' ];

    if (pageType) {
      let swiper = new Swiper ('.typeSwiper', {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<p class="' + className + '">' + (menu[index]) + '</p>';
          },
        },
      });
    }
  }

})();
export default typeSlider;
